'use client';

import { useCallback } from 'react';
import Link from 'next/link';
import { HomeIcon, Search, User } from 'lucide-react';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { Button } from 'src/components/Button/Button';
import { useMapSlugParam } from 'src/hooks/useMapSlugParam';
import { Translated } from 'src/i18n/TranslationsProvider';
import { MapCurrentEditors } from 'src/modules/MapLiveUpdates/MapCurrentEditors';
import { useModalActions } from 'src/store/modalsStore/modalsStore';
import { useUser } from 'src/store/user/userStore';
import { cn } from 'src/utils/cn';
import { Logo } from './Logo';
import { UserModal } from './UserModal';

// FIXME: if env(safe-area-inset-top) is missing, use pt-2

export function Navbar() {
  const user = useUser();
  const { openModal: openSearchModal } = useModalActions('search');

  const isMapView = Boolean(useMapSlugParam());
  const isMobile = useIsMobile();

  const iconSize = isMobile ? 32 : 24;
  const isMobileMap = isMobile ? !isMapView : true;

  const navBarRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) {
        return;
      }
      const pt = window.getComputedStyle(node).paddingTop;
      if (pt === '0' || pt === '0px') {
        node.style.paddingTop = '0.5rem';
      }
    },
    [isMobile]
  );

  return (
    <nav
      ref={navBarRef}
      data-testid="navbar"
      className={cn(
        'fixed inset-x-0 top-0 z-10',
        'flex h-navbar w-full items-center gap-2',
        'px-4 pb-2 pt-[env(safe-area-inset-top)] sm:pt-2',
        {
          'bg-background/50 backdrop-blur': isMobileMap,
        }
      )}
    >
      <div className="h-full w-8 flex-shrink-0 max-lg:hidden"></div>
      <div
        data-testid="navbar-left"
        className="flex items-center justify-start gap-4 sm:w-full"
      >
        {isMapView && isMobile && (
          <Link
            href={user ? '/my-maps' : '/'}
            passHref
            prefetch={false}
            className="flex items-center"
          >
            <Button rounded square variant="secondary">
              <HomeIcon width={iconSize} height={iconSize} />
            </Button>
          </Link>
        )}
        {isMobileMap && (
          <Link
            href={user ? '/my-maps' : '/'}
            passHref
            prefetch={false}
            className="flex items-center"
          >
            <Logo />
          </Link>
        )}
      </div>
      <div
        data-testid="navbar-center"
        className="flex flex-1 justify-center sm:min-w-[240px] xl:min-w-[480px]"
      >
        {isMapView && (
          <Button
            rounded={isMobile}
            padded={false}
            variant="secondary"
            onClick={openSearchModal}
            className="w-full justify-between px-4 py-2 normal-case text-primary/50 hover:text-primary hover:shadow-md dark:hover:text-primary sm:bg-backgroundLight sm:hover:bg-backgroundLight"
          >
            <div className="flex items-center gap-2">
              <Search
                height={24}
                width={24}
                className="stroke-[2px] only:mr-0"
                style={{ height: iconSize }}
              />
              <div className="text-base">
                <Translated id="search.placeholder" />
              </div>
            </div>
          </Button>
        )}
      </div>
      <div
        data-testid="navbar-right"
        className="flex items-center justify-end gap-4 sm:w-full"
      >
        {user && (
          <UserModal userAvatarUrl={user.avatar_url} userName={user.name} />
        )}
        {!user && (
          <Link href="/login" passHref className="flex-shrink-0">
            <Button
              variant={isMobile && isMapView ? 'secondary' : 'transparent'}
              rounded={isMobile}
              square={isMobile}
            >
              {isMobile ? (
                <User width={iconSize} height={iconSize} />
              ) : (
                <Translated id="sign-in" />
              )}
            </Button>
          </Link>
        )}
      </div>
      <div className="h-full w-8 flex-shrink-0 max-lg:hidden">
        {isMapView && <MapCurrentEditors />}
      </div>
    </nav>
  );
}
