import { produce } from 'immer';
import { create } from 'zustand';

interface Store {
  readonly modals: Record<string, boolean>;
  readonly actions: {
    openModal: (id: string) => void;
    closeModal: (id: string) => void;
    toggle: (id: string, open?: boolean) => void;
    openModalClosingOthers: (id: string) => void;
  };
}

const useStore = create<Store>()((set) => ({
  actions: {
    closeModal: (id) =>
      set(
        produce<Store>((state) => {
          state.modals[id] = false;
        })
      ),
    openModal: (id) =>
      set(
        produce<Store>((state) => {
          state.modals[id] = true;
        })
      ),
    openModalClosingOthers: (id) =>
      set(
        produce<Store>((state) => {
          state.modals = Object.keys(state.modals).reduce<Store['modals']>(
            (modals, key) => {
              modals[key] = key === id;
              return modals;
            },
            {}
          );
          state.modals[id] = true;
        })
      ),
    toggle: (id, open) =>
      set(
        produce<Store>((state) => {
          state.modals[id] = open ?? !state.modals[id];
        })
      ),
  },
  modals: {},
}));

export const useModalState = (id: string) =>
  useStore((state) => state.modals[id]) ?? false;

export const useModalActions = (id: string) => {
  const { closeModal, openModal, toggle, openModalClosingOthers } = useStore(
    (state) => state.actions
  );

  return {
    closeModal: () => closeModal(id),
    openModal: () => openModal(id),
    openModalClosingOthers: () => openModalClosingOthers(id),
    toggle: (open?: boolean) => toggle(id, open),
  };
};
