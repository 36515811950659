import { usePostHog } from 'posthog-js/react';
import type { AnalyticsEvent } from './analytics-events';

type EventName = keyof AnalyticsEvent;

type TrackEventParams<T extends EventName> = AnalyticsEvent[T] extends never
  ? [eventName: T]
  : [eventName: T, properties: AnalyticsEvent[T]];

export const useAnalytics = () => {
  const posthog = usePostHog();

  const trackEvent = <T extends EventName>(...params: TrackEventParams<T>) => {
    const [eventName, properties] = params;
    posthog.capture(eventName, properties);
  };

  return {
    trackEvent,
  };
};
