'use client';

import * as Avatar from '@radix-ui/react-avatar';
import * as HoverCard from '@radix-ui/react-hover-card';
import { UsersIcon } from 'lucide-react';
import { useBoolean } from 'src/hooks/useBoolean';
import { useStore } from 'zustand';
import { mapCurrentEditorsStore } from './mapCurrentEditorsStore';

export function MapCurrentEditors() {
  const [isCardOpen, { toggle: setIsCardOpen }] = useBoolean(false);
  const currentMapEditors = useStore(
    mapCurrentEditorsStore,
    (state) => state.currentEditors
  );

  if (currentMapEditors.length === 0) {
    return null;
  }

  return (
    <HoverCard.Root
      open={isCardOpen}
      onOpenChange={setIsCardOpen}
      openDelay={200}
    >
      <HoverCard.Trigger asChild>
        <div className="hidden h-full w-full items-center justify-center md:flex">
          <UsersIcon className="stroke-[1.5px] text-secondary hover:stroke-[2px]" />
        </div>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          sideOffset={-4}
          align="end"
          className="z-10 max-w-[320px] bg-backgroundLight shadow-lg shadow-dark/50"
        >
          <HoverCard.Arrow
            height={8}
            width={16}
            className="fill-backgroundLight"
          />
          <div className="flex flex-col gap-2 p-2">
            <div className="text-xs">Mapę również edytują:</div>
            {currentMapEditors.map((editor) => (
              <div key={editor.name} className="flex items-center gap-2">
                <Avatar.Root
                  className="h-[28px] w-[28px] select-none items-center justify-center overflow-hidden rounded-full align-middle"
                  key={editor.name}
                >
                  <Avatar.Image
                    className="h-full w-full rounded-[inherit] object-cover"
                    src={editor.avatarUrl}
                    alt={editor.name}
                  />
                  <Avatar.Fallback
                    className="flex h-full w-full items-center justify-center bg-secondary font-melodrama text-white"
                    delayMs={100}
                  >
                    {editor.name.charAt(0)}
                  </Avatar.Fallback>
                </Avatar.Root>
                <div className="small-caps">{editor.name}</div>
              </div>
            ))}
          </div>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}
