'use client';

import React, { useEffect } from 'react';
import Link from 'next/link';
import * as Popover from '@radix-ui/react-popover';
import {
  LogOut as LogOutIcon,
  Map as MapIcon,
  User,
  User as UserIcon,
} from 'lucide-react';
import { useIsMobile } from 'src/app/(providers)/DeviceContext';
import { ThemeToggle } from 'src/app/(providers)/ThemeProvider';
import { Button } from 'src/components/Button/Button';
import { Divider } from 'src/components/Divider/Divider';
import { useMapSlugParam } from 'src/hooks/useMapSlugParam';
import { Translated } from 'src/i18n/TranslationsProvider';
import { useAnalytics } from 'src/modules/analytics/useAnalytics';
import { cn } from 'src/utils/cn';
import { getSupabaseBrowserClient } from 'src/utils/supabase/getSupabaseBrowserClient';
import { Drawer } from 'vaul';

type Props = {
  userName: string;
  userAvatarUrl?: string;
};

export function UserModal(props: Props) {
  const [open, setOpen] = React.useState(false);
  const supabase = getSupabaseBrowserClient();
  const isMapView = Boolean(useMapSlugParam());
  const isMobile = useIsMobile();
  const { trackEvent } = useAnalytics();

  const iconSize = isMobile ? 32 : 24;

  const handleUserSignOut = async () => {
    trackEvent('user_signed_out');
    setOpen(false);
    await supabase.auth.signOut();
    // force refresh window to prevent opening cached routes
    window.location.replace('/');
  };

  const close = () => setOpen(false);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  if (isMobile) {
    return (
      <Drawer.Root open={open} scrollLockTimeout={0} onOpenChange={setOpen}>
        <Drawer.Trigger asChild>
          <Button
            rounded={isMobile}
            square={isMobile}
            variant={isMobile && isMapView ? 'secondary' : 'transparent'}
            className="small-caps"
          >
            <User
              width={iconSize}
              height={iconSize}
              className={'sm:mr-2 sm:hidden'}
            />
            <div className="hidden text-xl sm:block">{props.userName}</div>
          </Button>
        </Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 z-[200] bg-dark/80 backdrop-blur-xl" />
          <Drawer.Content className="fixed bottom-0 left-0 right-0 z-[200] flex max-h-[96vh] flex-col rounded-t-[16px] bg-background pt-8 outline-none">
            <div className="absolute left-1/2 top-3 z-[1] h-[4px] w-[30vw] -translate-x-1/2 rounded-full border border-transparent bg-secondary/50 sm:hidden" />
            <div className="scrollbar-hide flex h-full min-h-[60vh] flex-col gap-4 px-4">
              {/* USER AVATAR */}
              <div className="flex flex-col items-center justify-center gap-4">
                <picture className="h-24 w-24 sm:h-24 sm:w-24">
                  {props.userAvatarUrl && (
                    <>
                      <source srcSet={props.userAvatarUrl} type="image/webp" />
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        className="h-full w-full rounded-full object-cover"
                        src={props.userAvatarUrl}
                        referrerPolicy="no-referrer"
                        alt="Profile picture"
                      />
                    </>
                  )}
                  {!props.userAvatarUrl && (
                    <UserIcon className="h-full w-full rounded-full border border-secondary stroke-[0.5] text-secondary sm:border-2" />
                  )}
                </picture>
                <div className="flex flex-col items-center">
                  <div className="small-caps text-3xl">{props.userName}</div>
                </div>
              </div>
              <Divider />
              <div className="flex flex-col items-center gap-4 sm:mx-auto">
                <Link href="/my-maps" onClick={close}>
                  <Button className="w-full" variant="transparent">
                    <MapIcon className="mr-6 text-xl" />
                    <Translated id="navbar.my-maps" />
                  </Button>
                </Link>
                <ThemeToggle className="w-full" />
              </div>
              <div className="mt-auto flex flex-col gap-4 pb-6">
                <Button onClick={handleUserSignOut} className="self-center">
                  <LogOutIcon className="mr-4 text-xl" />
                  <Translated id="navbar.sign-out" />
                </Button>
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    );
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          variant="transparent"
          className={cn('small-caps', open && 'bg-primary text-background')}
        >
          <div className="hidden text-xl sm:block">{props.userName}</div>
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="z-[200] flex flex-col gap-4 bg-backgroundLight p-2 text-sm shadow-lg shadow-dark/50"
          align="end"
          sideOffset={6}
        >
          <Link href="/my-maps" onClick={close} className="w-full">
            <Button
              variant="transparent"
              className="w-full justify-between gap-4"
            >
              <MapIcon />
              <Translated id="navbar.my-maps" />
            </Button>
          </Link>
          <ThemeToggle
            square={false}
            variant="transparent"
            className="w-full justify-between gap-4"
            onClick={close}
          />
          <Button
            variant="transparent"
            onClick={handleUserSignOut}
            className="w-full justify-between gap-4"
          >
            <LogOutIcon />
            <Translated id="navbar.sign-out" />
          </Button>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
