import { create } from 'zustand';

interface State {
  currentEditors: Array<{
    name: string;
    avatarUrl?: string;
  }>;
}

interface Actions {
  addEditor: (editor: State['currentEditors'][number]) => void;
  removeEditor: (editor: State['currentEditors'][number]) => void;
  setEditors: (editors: State['currentEditors']) => void;
}

type Store = State & { actions: Actions };

export const mapCurrentEditorsStore = create<Store>()((set) => ({
  actions: {
    addEditor: (editor) =>
      set((state) => ({ currentEditors: [...state.currentEditors, editor] })),
    removeEditor: (editor) =>
      set((state) => ({
        currentEditors: state.currentEditors.filter(
          (e) => e.name !== editor.name
        ),
      })),
    setEditors: (editors) => set(() => ({ currentEditors: editors })),
  },
  currentEditors: [],
}));
