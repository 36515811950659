export function Logo() {
  return (
    <svg
      width="400"
      height="165"
      viewBox="0 0 400 165"
      xmlns="http://www.w3.org/2000/svg"
      className="h-navbar w-auto"
    >
      <g>
        <path
          d="M64.25 127H55.802V38.296H64.25V127ZM63.354 125.336H64.89C71.6313 125.336 77.69 123.544 83.066 119.96C88.442 116.291 92.7087 111.256 95.866 104.856C99.0233 98.3707 100.602 90.9467 100.602 82.584C100.602 74.2213 99.0233 66.84 95.866 60.44C92.7087 54.04 88.442 49.048 83.066 45.464C77.69 41.7947 71.6313 39.96 64.89 39.96H63.354V38.296H64.89C71.2047 38.296 77.05 39.4053 82.426 41.624C87.8873 43.7573 92.6233 46.7867 96.634 50.712C100.73 54.6373 103.887 59.3307 106.106 64.792C108.41 70.168 109.562 76.0987 109.562 82.584C109.562 89.0693 108.41 95.0427 106.106 100.504C103.887 105.88 100.73 110.573 96.634 114.584C92.6233 118.509 87.8873 121.581 82.426 123.8C77.05 125.933 71.2047 127 64.89 127H63.354V125.336ZM129.988 38.296V103.064C129.988 109.72 131.524 115.181 134.596 119.448C137.753 123.629 142.105 125.72 147.652 125.72C151.236 125.72 154.351 124.781 156.996 122.904C159.727 120.941 161.817 118.253 163.268 114.84C164.804 111.341 165.572 107.416 165.572 103.064V38.296H167.364V103.064C167.364 108.013 166.425 112.408 164.548 116.248C162.671 120.003 160.025 122.947 156.612 125.08C153.284 127.213 149.316 128.28 144.708 128.28C140.185 128.28 136.132 127.256 132.548 125.208C129.049 123.075 126.319 120.131 124.356 116.376C122.393 112.536 121.412 108.099 121.412 103.064V38.296H129.988ZM176.108 38.296H185.068L221.932 127H212.972L176.108 38.296ZM197.612 81.816L198.636 83.864L178.924 127H176.876L197.612 81.816ZM200.044 83.864L199.02 81.816L218.988 38.296H221.036L200.044 83.864ZM233.427 127V38.296H235.219V127H233.427ZM281.939 127V38.296H290.387V127H281.939ZM262.867 94.872L259.155 107.416H258.899L234.323 40.088L233.939 38.296H242.643L262.867 94.872ZM283.731 38.296L259.155 107.416H258.899L258.387 104.728L281.939 38.296H283.731ZM314 127H305.552V38.296H314V127ZM312.72 39.96V38.296H346.512V39.96H312.72ZM312.72 83.48V81.816H343.568V83.48H312.72ZM312.72 127V125.336H346.512V127H312.72Z"
          fill="currentColor"
        />
        <circle cx="26" cy="82" r="6" fill="currentColor" />
        <circle cx="374" cy="82" r="6" fill="currentColor" />
      </g>
    </svg>
  );
}
