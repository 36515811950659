import { useParams } from 'next/navigation';

export const useMapSlugParam = () => {
  const params = useParams();

  if ('mapSlug' in params) {
    return params.mapSlug as string;
  }
  return;
};
